<template>
    <div class="page">
        <banner></banner>
        <div class="tuijian-wrap w-1300">
            <div class="center-text">
                <p></p>
                <span>推荐商城</span>
            </div>
            <div class="tuijian-list">
                <div class="tuijian-item" v-for="(item,index) in ggList" :key="index">
                    <div class="tuijian-top">
                        <div class="tuijian-text">
                            <div class="tuijian-p">{{item.name}}</div>
                            <div class="tuijian-p1" v-html="delLabel(item.content)"></div>
                        </div>
                    </div>
                    <div class="tuijian-bottom">
                        <!-- <div class="tuijian-bt" @click="goTo('/shop_introduce',{id:item.id})">平台简介</div> -->
                        <div class="tuijian-bt" @click="goTo('/recom',{id:item.id,hzy_id:$route.query.hzy_id})">简介</div>
                        <div class="tuijian-bt" @click="goTo('/enter',{id:item.plate_id,hzy_id:$route.query.hzy_id})">申请入驻</div>
                    </div>
                </div>
            </div>

            <div class="gengduo">
                <div class="center-text">
                    <p></p>
                    <span>商城分类</span>
                </div>
                <div class="more" @click="goTo('/shop_state')">更多国家区域 <i class="el-icon-arrow-right"></i></div>
            </div>
            
            <div class="shop-wrap">
                <div class="shop-left">
                    <div class="pinpai-list-text">商城分类<p></p></div>
                    <div class="pinpai-item-wrap">                        
                        <div class="pinpai-item" v-for="(item,index) in guojiaList" :key="index"  @click="tabClick(item.id)">
                            <p :class="{active:id==item.id}">{{item.name}}</p>
                            <img src="../../../assets/images/right.png" alt="">
                        </div>
                        
                    </div>
                </div>
                <div class="shop-right">
                    <div class="shop-item" v-for="(item,index) in liebiaoList" :key="index">
                        <div class="shop-top">
                            <div class="shop-img"><img :src="item.banner_url" alt=""></div>
                            <div class="shop-text">
                                <div class="shop-p">{{item.name}}</div>
                                <div class="shop-p1" v-html="delLabel(item.content)"></div>
                            </div>
                        </div>
                        <div class="shop-bottom">
                            <div class="shop-bt" @click="goTo('/shop_introduce',{id:item.id})">简介</div>
                            <div class="shop-bt" @click="goTo('/enter',{id:item.plate_id})">申请入驻</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import banner from '../../../components/banner.vue';
    import {getplatexts ,getcountrys} from '../../../api/index';
    import {mapState, mapMutations, mapActions} from 'vuex'  
    export default {
        data() {
            return {
                ggList:[],  
                guojiaList:[],
                liebiaoList:[],
                id:''
            }
        },
        components:{
            banner
        },
        computed: {
            ...mapState(['isLogin'])         
        },
        created() {
            this.getplateAds(); 
            this.getcountrys();
            // this.getplatexts()
        },
        methods: {
            // 去除富文本所有标签
            delLabel(input){
                if (input!=null) {
                    return input.replace(/<\/?[^>]*>/g, ""); 
                }
            },
            // 跳转页面
            goTo(url,query) {
                this.$router.push({ 
                    path: url ,
                    query
                });
            },
            // 跳转外链
            goUrl(url,num){
                if(num==1){
                    window.open(url) 

                }else {                    
                    if ( !this.isLogin) {                    
                        this.$confirm('请您先登录注册会员','温馨提示', {
                            confirmButtonText: '去登录',
                            }).then(() => {
                                this.$router.push({
                                    path:'/login'
                                })
                            }).catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '请登录后再操作'
                                });
                        });
                        return;
                    }
                    window.open(url)
                }
                 
            },
            tabClick(id){
                console.log(id)
                this.id=id;
                this.getplatexts();
            },
            // 获取推荐
            getplateAds(){
                // this.tuijianLoading=true
                getplatexts({
                    plate_id:7,
                    recom:1,
                }).then((res)=>{
                    setTimeout(() => {
                        this.tuijianLoading=false
                    }, 200);
                    // console.log(res)
                    if (res.code==200) {
                        this.ggList=res.data.data;
                    }
                })
            },
            //获取国家列表
            getcountrys(){
                getcountrys().then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.guojiaList=res.data;
                        if (res.data.length>0) {
                            this.id=res.data[0].id
                            this.getplatexts()
                        }                        
                    }
                })
            },
            //获取右侧内容
            getplatexts(){
                console.log(this.id)
                getplatexts({
                    plate_id:7,
                    coun_id:this.id,
                    per_page:16
                }).then((res)=>{
                    // console.log(res)
                    if (res.code==200) {
                        this.liebiaoList=res.data.data;
                    }
                })
            },

            
        },
        
    }
</script>

<style lang="less" scoped>
    .page{overflow: hidden;}
    .tuijian-wrap{
        margin: auto;
        .tuijian-list{
            margin-top: 40px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .tuijian-item{
                width: 18%;
                margin-right: 2.5%;
                padding: 0 10px;
                border: 1px solid #E8E8E8;
                background: #FFFFFF;
                box-sizing: border-box;
                margin-bottom: 15px;
                .tuijian-top{
                    padding: 30px 0 0 0;
                    text-align: center;
                    .tuijian-text{
                        .tuijian-p{color: #fff;font-size: 24px;font-weight: bold;margin-bottom: 6px;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;}
                        .tuijian-p1{color: #fff;font-size: 16px;font-weight: 400;    overflow: hidden; white-space: nowrap;text-overflow: ellipsis;}
                    }
                }
                .tuijian-bottom{
                    padding: 20px 0;
                    display: flex;justify-content: space-between;
                    .tuijian-bt{width: 45%;height: 30px;line-height: 30px;text-align: center;background: #fff;border-radius: 14px;
                        color: #666666;font-size: 14px;font-weight: 400;border-radius: 15px;cursor: pointer;
                        &:hover{color: #F15012;}
                    }
                }
                &:nth-child(5n+0){margin-right: 0;background: url('../../../assets/images/jksctj5.png');}
                &:nth-child(1){background: url('../../../assets/images/jksctj1.png');}
                &:nth-child(5n+1){background: url('../../../assets/images/jksctj1.png');}
                &:nth-child(2){background: url('../../../assets/images/jksctj2.png');}
                &:nth-child(5n+2){background: url('../../../assets/images/jksctj2.png');}
                &:nth-child(3){background: url('../../../assets/images/jksctj3.png');}
                &:nth-child(5n+3){background: url('../../../assets/images/jksctj3.png');}
                &:nth-child(4){background: url('../../../assets/images/jksctj4.png');}
                &:nth-child(5n+4){background: url('../../../assets/images/jksctj4.png');}
            }
        }
        .gengduo{
            margin-top: 45px;
            .center-text{margin-top: 0;}
            display: flex;
            align-items: center;
            justify-content: space-between;
            .more{
                color: #0071DB;
                font-size: 18px;
                cursor: pointer;
            }
        }
        .shop-wrap{
            margin-top: 45px;
            margin-bottom: 140px;
            display: flex;
            .shop-left{
                width: 20%;
                .pinpai-list-text{position: relative;
                    height: 43px;line-height: 43px; background: #0071DB;padding-left: 45px;font-size: 18px;font-weight: bold;color: #fff;
                    p{width: 11px;height: 11px;background: #fff;border-radius: 50%;position: absolute;top: 16px;left: 22px;}    
                }
                .pinpai-item-wrap{width: 100%;background: #EEEFF0;padding-bottom: 28px;}
                .pinpai-item{padding: 28px 37px 0 45px;display: flex;justify-content: space-between;align-items: center;cursor: pointer;
                    p{color: #333333;font-size: 18px;}
                    img{width: 8px;}
                    &:hover{
                        p{color: #0071DB;}
                    }
                    .active{color: #0071DB !important;}
                }
            }
            .shop-right{
                width: 80%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: flex-start;
                .shop-item{
                    width: 23%;
                    margin-left: 2%;
                    padding: 0 10px;
                    border: 1px solid #E8E8E8;
                    background: #FFFFFF;
                    box-sizing: border-box;
                    margin-bottom: 35px;
                    .shop-top{
                        padding: 20px 0 15px 0;
                        display: flex;
                        border-bottom: 1px solid #E8E8E8;
                        .shop-img{
                            width: 30%;height:50px;flex-shrink: 0;
                            img{width: 100%;height: 100%;object-fit: cover;}
                        }
                        .shop-text{
                            width: 65%;margin-left: 5%;
                            .shop-p{color: #000000;font-size: 16px;font-weight: bold;margin-bottom: 6px;}
                            .shop-p1{color: #666666;font-size: 14px;font-weight: 400;overflow: hidden;white-space: nowrap; text-overflow: ellipsis;}
                        }
                    }
                    .shop-bottom{
                        padding: 15px 0;
                        display: flex;justify-content: space-between;
                        .shop-bt{width: 45%;height: 30px;line-height: 30px;text-align: center;background: #F4F6F7;
                            color: #666666;font-size: 14px;font-weight: 400;border-radius: 15px;cursor: pointer;
                            &:hover{background: #0071DB;color: #fff;}
                        }
                    }
                    &:hover{
                        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.16);
                    }
                    // &:nth-child(5n+0){margin-right: 0;}
                }

            }
        }
        
    }
</style>